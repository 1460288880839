<template>
    <v-col xl="12 pa-0">
        <v-row class="template-variables ma-0">
            <span class="template-variables__title"> VARIABLES : </span>
            <span @click="$emit('click', variable.value)" class="pa-1" v-for="(variable,i) in templateVariables" :key="i">
                <div class="content">
                    <div class="label"> {{ variable.label }}  </div>
                </div>
            </span>
        </v-row>
    </v-col>

</template>
<script>
    export default {
        components: {},
        props: {
            templateVariables: { default: '' },
        },
        data: () => ({
        }),
    };
</script>


<style scoped>
    .template-variables { 
        display: flex;
        align-items: center;
    }
    .template-variables .template-variables__title {
        font-family: "Montserrat-light", sans-serif;
        font-size: 18px;
    }
    .template-variables .content {
        border-radius: 5px;
        border: 1px solid #d6d6d6;
        padding: 4px 12px;
        cursor: pointer;
        transition: .2s cubic-bezier(1,0,.2,1);
        background: linear-gradient(to right, #eeeeee 50%, var(--color-sms) 50%);
        background-size: 200% 100%;
        background-position:left bottom;
    }
    .template-variables .label {
        font-family: "Montserrat-light", sans-serif;
        color: #000;
        font-size: 18px;
        transition: .2s cubic-bezier(1,0,.2,1);
        white-space: nowrap;
    }
    /*----------------------------Hover State----------------------------------*/
    .template-variables .content:hover {
        background-position:right bottom;
    }
    .template-variables .content:hover .label {
        color: #fff;
    }
</style>
