<template>
  <basic-modal width="1200" transition="slide-x-transition">
    <template #header>
      <divider
        title="add an email tempalte"
        sub-title="create and use it later"
        size="small"
      ></divider>
    </template>
    <template #default>
      <div class="mt-4 px-12">
        <div class="mb-16">
          <section-title title="recipients"></section-title>
          <v-row class="mx-0">
            <v-col cols="12" md="10" class="pl-3 pl-md-0">
              <v-row class="pt-2">
                <v-col sm="4" md="3">
                  <RequestDetailsInformation
                    title="Total Recipients "
                    subTitle="form.first_name"
                    class="ma-0"
                  />
                </v-col>
                <v-col sm="4" md="3">
                  <li class="request__details__information">
                    <div class="d-block ml-1">
                      <div class="request__details__information--title">
                        Active Filters
                      </div>
                      <div class="mt-n1">
                        <v-chip
                          color="#e6e6e682"
                          text-color="black"
                          label
                          x-small
                          class="active__filters"
                        >
                          fas
                        </v-chip>
                      </div>
                    </div>
                  </li>
                </v-col>
                <v-col sm="4" md="6"
                  ><RequestDetailsInformation
                    title="data / time"
                    subTitle="form.model.job_experience"
                    class="ma-0"
                /></v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <div class="mb-16 pb-16">
          <section-title title="email content"></section-title>
          <v-row class="mx-0 pt-2">
            <v-col cols="12" md="10" class="pl-3 pl-md-0">
              <v-row>
                <v-col cols="12">
                  <RequestDetailsInformation
                    title="Title"
                    subTitle="form.first_name"
                    class="ma-0"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="pt-0">
                  <RequestDetailsInformation
                    title="Text"
                    subTitle="form.first_name"
                    class="ma-0"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </div>
    </template>
    <template #footer>
      <v-spacer></v-spacer>
      <div
        class="
          px-7
          pt-2
          pb-3
          d-flex
          justify-space-between
          align-items-center
          w-100
        "
      >
        <block-button
          class="btn__modal--cancel"
          text="cancel"
          @click.native="$_closeModal()"
          height="30"
        ></block-button>
        <block-button
          height="30"
          @click.native="$_closeModal()"
          class="btn__modal--assign site__button width-200 ml-5"
          text="ok, thank you"
        ></block-button>
      </div>
    </template>
  </basic-modal>
</template>
<script>
import SectionTitle from "@/components/Global/Section/SectionTitle.vue";
import RequestDetailsInformation from "@/components/Global/Section/RequestDetailsInformation.vue";
export default {
  components: {
    SectionTitle,
    RequestDetailsInformation,
  },
  name: "modal_email_template",
};
</script>
<style scoped>
.request__details__information {
  display: flex;
  flex-direction: row;
}
.request__details__information::before {
  height: 24px;
  background-color: var(--color-light-gray);
  content: "";
  display: block;
  width: 1px;
  margin: 7px 2px 0 0;
}
.request__details__information--title {
  color: var(--color-smoky);
  font-size: 11px;
  font-family: "Montserrat-ExtraLight";
  text-transform: uppercase;
}
.active__filters {
  font-size: 14px;
  padding: 10px 17px;
  font-family: "Montserrat-semiBold";
}
.btn__modal--cancel {
  font-size: 18px;
}
</style>
