<template >
  <div class="wa__container">
    <header class="pt-3">
      <div class="pt-3">
        <v-row>
          <v-col cols="12">
            <site-header
              backRouteName="emailManagment"
              :backText="$_t('backText')"
              :title="$_t('title')"
              :sub-title="$_t('subTitle')"
            ></site-header>
          </v-col>
        </v-row>
      </div>
    </header>
    <main>
      <div>
        <v-row class="justify-content-center pb-4 wa__models">
          <v-col cols="12" md="2">
            <EmailTemplatesFilter />
          </v-col>
          <v-col cols="12" md="10">
            <EmailTemplatesItems />
          </v-col>
        </v-row>
      </div>
    </main>
    <footer>
      <div class="wa__home--footer">
        <site-footer :text="$_t('footerText')"></site-footer>
      </div>
    </footer>
    <!-- --------------------------------------------------------------------
                                Modals :: BEGIN
    ---------------------------------------------------------------------->
    <template>
        <div class="text-center">

        </div>
    </template>
    <!-- --------------------------------------------------------------------
                                Modals :: END
    ---------------------------------------------------------------------->
  </div>
</template>
<script>
import EmailTemplatesItems from "@/components/EmailTemplates/Items";
import EmailTemplatesFilter from "@/components/EmailTemplates/Filter";
export default {
  components: {
    EmailTemplatesFilter,
    EmailTemplatesItems,
  },
  computed: {
  },
  // TODO: fix clear filter later
};
</script>