<template>
  <basic-modal @open="setData" width="1200" transition="slide-x-transition">
    <template #header>
      <divider
        title="add an email tempalte"
        sub-title="create and use it later"
        size="small"
      ></divider>
    </template>
    <template #default>
      <div class="mt-4 px-12">
        <v-row>
          <v-col cols="12" md="8">
            <v-text-field
              label="email title"
              color="SonicSilver"
              class="mt-0 pt-0"
              outlined
              dense
              v-model="form.title"
            ></v-text-field>
          </v-col>
        </v-row>
        <div v-if="showEditor">
          <editor ref="message_textarea" v-model="form.message" :init="init"></editor>
        </div>
        <!--        <v-textarea-->
<!--          ref="message_textarea"-->
<!--          label="email text"-->
<!--          color="SonicSilver"-->
<!--          class="mt-0 pt-0"-->
<!--          outlined-->
<!--          dense-->
<!--          v-model="form.message"-->
<!--        ></v-textarea>-->

        <div>
          <v-col cols="12" class="pt-2">
            <variables-table
              @click="addVar"
              :templateVariables="emailTemplateVariables"
            />
          </v-col>
        </div>
      </div>
    </template>
    <template #footer>
      <v-spacer></v-spacer>
      <div class="px-7 pt-2 pb-3 d-flex justify-space-between align-items-center w-100">
        <block-button
          class="btn__modal--cancel btn__size__18"
          text="cancel"
          @click.native="$_closeModal()"
          height="30"
        ></block-button>
        <block-button
          height="30"
          class="btn__modal--assign site__button width-200 ml-5 btn__size__16"
          text="save"
          :loading="requestLoading"
          @click.native="send"
        ></block-button>
      </div>
    </template>
  </basic-modal>
</template>

<script>
import VariableTable from "@/components/EmailTemplates/modals/Variable-Table.vue";
import { mapActions } from "vuex";
import { makeid } from "@/utils/math";
import { initTiny } from "@/plugins/tinymce/tinymce";
import Editor from "@tinymce/tinymce-vue";
const RANDOM_TOKEN = makeid(50);
var formDefault = {
  thumbnail: "https://via.placeholder.com/1280x720",
  file_batch_id: RANDOM_TOKEN,
  tinymce_batch_id: makeid(50),
  description: "",
};
export default {
  name: "modal_add_email_template",
  components: {
    Editor,
    "variables-table": VariableTable,
  },
  data() {
    return {
      showEditor: false,
      init: initTiny(formDefault.tinymce_batch_id,this.$_getLocale(),'email','attachments'),
      form: {
        message: "",
      },
      emailTemplateVariables: [
        { label: "First name", value: " {first_name} " },
        { label: "Last Name", value: " {last_name} " },
      ],
      requestLoading: false,
    };
  },
  methods: {
    setData({ model }) {
      if (model) {
        this.form = {
          ...model,
        };
      }
    },
    addVar(value) {
      if (this.isDisabled) {
        return;
      }
      // let textarea = this.$refs.message_textarea;
      // const startPos = textarea.$el.querySelector("textarea").selectionStart;
      // const endPos = textarea.$el.querySelector("textarea").selectionEnd;
      // this.form.message =
      //   this.form.message.substring(0, startPos) +
      //   value +
      //   this.form.message.substring(endPos, this.form.message.length);
      // textarea.$el.querySelector("textarea").focus();
      this.form.message = this.form.message.concat(value)
    },
    ...mapActions("template", ["storeTemplate", "updateTemplate"]),
    async send() {
      try {
        this.requestLoading = true;
        if (this.form.id) {
          await this.updateTemplate(this.form);
        } else {
          await this.storeTemplate(this.form);
        }
        this.$_closeModal();
      } catch (e) {
        return e;
      } finally {
        this.requestLoading = false;
      }
    },
  },
  mounted() {
    this.showEditor = true;
  }
};
</script>

<style scoped>
.title {
  color: var(--color-light-gray);
  font-family: "Montserrat-regular" !important;
  font-size: 9px !important;
  height: 22px;
}

.btn__modal--assign {
  padding: 20px 35px !important;
  font-family: "Montserrat-ExtraLight";
}
</style>
