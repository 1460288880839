<template>
  <div class="wa__model__clients mb-1">
    <v-row class="text-left h-inherit ml-2 mr-2">
      <!--name  section-->
      <v-col cols="5" class="h-100">
        <div class="d-flex align-center h-100" style="overflow: hidden">
          <div style="line-height: 14px">
            <div class="client__name wa__f__m__eb">{{ template.title }}</div>
            <small class="client__cell">{{ template.created_at }}</small>
          </div>
        </div>
      </v-col>
      <!--slug secttion-->
      <v-col cols="2" class="wa__model__status d-none d-lg-block pl-5">
        <!--slug -->
        <div class="d-flex h-100 align-center">
          <block-button
            v-if="template.is_systematic"
            :text="template.slug"
            class="btn-available"
            color="cyan"
            bg-color="bgCyan"
            height="24"
          ></block-button>
        </div>
      </v-col>
      <!-- control section-->
      <v-col cols="5" class="h-100">
        <div class="d-flex align-center h-100 justify-content-end">
          <circle-button
            icon="WMi-pencil"
            color="black"
            lg
            @click.stop.native="$_openModal('add_email_template', { model: template })"
          ></circle-button>
          <circle-button
            icon="WMi-trash"
            color="red"
            lg
            bg-color="bgLightPink"
            @click.stop.native="deleteMessage"
          ></circle-button>
        </div>
      </v-col>
      <!-- /control section-->
    </v-row>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import toast from "@/utils/toast";
export default {
  components: {
    // Name,
  },
  props: {
    template: {
      type: Object,
    },
  },
  methods: {
    ...mapActions("template", ["deleteTemplate"]),
    deleteMessage() {
      toast.question(this.$_trans("toast.del_email_template_item_msg"), this.$_trans("toast.del_email_template_item_title"), () => {
        this.deleteTemplate(this.template.id);
      });
    },
  },
};
</script>
<style scoped>
.wa__model__clients {
  border: 1px solid var(--color-light-gray);
  border-radius: 5px;
  width: 100%;
  height: 65px;
  font-family: "Montserrat", sans-serif;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.client__name {
  font-family: "montserrat-light";
  font-size: 20px;
  text-transform: uppercase;
}
.client__cell {
  font-size: 10px;
  color: var(--color-smoky);
  font-family: "Montserrat-Light";
}
.wa__model__clients__name {
  display: flex;
  align-items: baseline;
  cursor: pointer;
}
.wa__model__clients__name--checked {
  padding: 0px 9px 0px 0;
}
.btn-request {
  font-size: 9px;
  font-family: "Montserrat-bold";
  letter-spacing: 2px !important;
  border-radius: 1px !important;
  cursor: default !important;
  position: relative;
  top: -3px;
}
.btn-approved {
  font-family: "Montserrat-bold";
  font-size: 13px;
  font-weight: 900;
  padding: 1px 8px !important;
  position: relative;
  top: -0.5rem;
  border-radius: 1px !important;
  letter-spacing: 2px;
}

.btn-allright {
  font-size: 14px;
  padding: 15px 12px !important;
  position: relative;
  top: -0.6rem;
  cursor: default !important;
  letter-spacing: 3px;
  margin-right: 20px;
}
.alert-header {
  background-color: var(--color-bg-modal-header);
  border-radius: 4px;
  padding: 17px 0 15px 0;
}

.request__detail li {
  display: inline-flex;
  margin-right: 65px;
}
.request__detail li:nth-child(6) {
  margin-right: 140px;
}
.h-inherit {
  height: inherit;
}
</style>
