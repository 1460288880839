<template>
  <list :title="$_t('templates')" class="wa__large" has-btn>
    <hr class="mt-0" />
    <template #header-btn>
      <div>
        <block-button
          text="add"
          icon="WMi-plus-linear"
          height="37"
          class="px-10 btn__size__18 site__button add__mode width-200 mt-n1"
          @click.native="$_openModal('add_email_template')"
        ></block-button>
      </div>
    </template>
    <template>
      <v-tabs color="black" v-model="activeTab" centered slider-color="black">
        <v-tab>
          <div class="d-flex align-center">
            <div class="mb-2">
              <div class="teb__name user__selected">USER CREATED</div>
            </div>
          </div>
        </v-tab>
        <v-tab>
          <div class="d-flex align-center">
            <div class="mb-2">
              <div class="teb__name system">SYSTEM</div>
            </div>
          </div>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="activeTab">
        <v-row class="d-none d-md-flex mt-3">
          <v-col cols="3" class="wa__model__title--title">
            <span class="pl-5">{{ $_t("itemsTitle") }}</span>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="3" class="wa__model__title--title text-right">
            <span class="pr-10">{{ $_t("controls") }}</span>
          </v-col>
        </v-row>
        <v-tab-item>
          <data-iterator
            :is-filtered="isFilteredTemplate"
            :loading="getTemplateLoading"
            :items="getTemplates.filter(x=>(!x.is_systematic))"
            @pagination="$emit('pagination', $event)"
            :pagination="getPaginationTemplate"
          >
            <template #loading>
              <loading />
            </template>
            <template #no-items>
              <no-items />
            </template>
            <template #items="{ itemNumber, item }">
              <TemplateItem
                :item-number="itemNumber"
                :is-lastest="itemNumber === getTemplates.length"
                :template="item"
              ></TemplateItem>
            </template>
          </data-iterator>
        </v-tab-item>
        <v-tab-item>
          <data-iterator            
            :is-filtered="isFilteredTemplate"
            :loading="getTemplateLoading"
            :items="getTemplates.filter(x=>(x.is_systematic))"
            @pagination="$emit('pagination', $event)"
            :pagination="getPaginationTemplate"
          >
            <template #loading>
              <loading />
            </template>
            <template #no-items>
              <no-items />
            </template>
            <template #items="{ itemNumber, item }">
              <TemplateItem
                :item-number="itemNumber"
                :is-lastest="itemNumber === getTemplates.length"
                :template="item"
              ></TemplateItem>
            </template>
          </data-iterator>
        </v-tab-item>
      </v-tabs-items>
      <!-- --------------------------------------------------------------------
                            Modals :: BEGIN
        ---------------------------------------------------------------------->
      <template>
        <div class="text-center">
          <EmailTemplateModal v-if="isModal('modal_add_email_template')" />
          <EmailDetailModal />
        </div>
      </template>
      <!-- --------------------------------------------------------------------
                                    Modals :: END
        ---------------------------------------------------------------------->
    </template>
  </list>
</template>

<script>
import TemplateItem from "./Item.vue";
import EmailTemplateModal from "@/components/EmailTemplates/modals/AddEmailTemplate.vue";
import EmailDetailModal from "@/components/EmailTemplates/modals/EmailDetail.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  components: {
    EmailTemplateModal,
    TemplateItem,
    EmailDetailModal,
  },
  data() {
    return {
      activeTab: null,
    };
  },
  computed: {
    ...mapGetters("template", [
      "getPaginationTemplate",
      "getTemplates",
      "getFiltersTemplate",
      "isFilteredTemplate",
      "getTemplateLoading",
    ]),
    ...mapGetters("modal", ["isModal"]),
  },
  methods: {
    ...mapActions("template", ["loadTemplates"]),
    ...mapMutations("template", ["SET_PAGINATION"]),
    changePagination(page) {
      if (this.getPaginationTemplate.page !== page) {
        this.SET_PAGINATION({
          pagination: page,
        });
        this.loadTemplates();
      }
    },
  },
  created() {
    this.loadTemplates();
  },
};
</script>

<style scoped>
.wa__model__title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  height: 16px;
}

.wa__model__title--title {
  font-family: "Montserrat";
  font-size: 12px;
  color: var(--color-light-gray);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.v-tab .teb__name {
  font-size: 20px;
  color: #000;
  font-family: "Montserrat-light";
}
</style>
<style>
.theme--light.v-tabs > .v-tabs-bar {
  background-color: rgba(255, 1, 1, 0) !important;
  border-bottom: 1px solid#E6E6E6;
}
.v-tab:not(.v-tab--active) div div .system,
.v-tab:not(.v-tab--active) div div .user__selected {
  color: #c3c3c3 !important;
}
</style>
