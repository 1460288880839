<template>
  <v-form @submit.prevent="submit" class="h-100 mb-3">
    <list :title="$_t('filters')" :filter="true" class="h-100">
      <hr class="mt-0" />
      <v-row>
        <v-col cols="12">
          <div class="h-35">
            <v-text-field
              :label="$_t('name')"
              class="no-error-msg"
              color="SonicSilver"
              outlined
              dense
              v-model="filter.title.val"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12">
          <div>
            <date-input
              :label="$_t('dateAfter')"
              v-model="filter.created_at.val1"
              color="SonicSilver"
              class="no-error-msg"
              outlined
              dense
            ></date-input>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="h-35">
            <date-input
              :label="$_t('dateBefore')"
              class="no-error-msg mt-n2"
              v-model="filter.created_at.val2"
              outlined
              dense
              color="SonicSilver"
            ></date-input>
          </div>
        </v-col>
      </v-row>
      <v-row class="my-3">
        <v-col cols="4" class="pr-0">
          <block-button
            height="30"
           class="w-100 cancel__button br__mode btn__size__14"
            :text="$_t('clear')"
            color="black"
            @click.native="clearFilter"
            outlined
            dense
          ></block-button>
        </v-col>
        <v-col cols="8 pl-1">
          <block-button
            height="30"
            class="w-100 site__button width-auto add__mode btn__size__18"
            type="submit"
            :text="$_t('execute')"
          ></block-button>
        </v-col>
      </v-row>
    </list>
  </v-form>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { cloneDeep } from "lodash";

const defaultFilter = {
  created_at: {
    type: "between",
    val1: null,
    val2: null,
  },
  title: {
    type: "like",
    val: null,
  },
};
export default {
  data: () => ({
    filter: {},
  }),
  computed : {
    ...mapGetters('template', ['getFiltersTemplate']),
  },
  methods: {
    submit() {
      this.setIsFiltered(true);
      this.SET_FILTER_TEMPLATE(cloneDeep(this.filter));
      this.loadTemplates();
    },
    ...mapMutations("template", ["SET_FILTER_TEMPLATE"]),
    ...mapActions("template", ["loadTemplates", "setIsFiltered"]),
    clearFilter() {
      this.setIsFiltered(false);
      this.filter = cloneDeep(defaultFilter);
      this.SET_FILTER_TEMPLATE(cloneDeep(this.filter));
      this.loadTemplates();
    },
  },
  created(){
    this.filter = {...defaultFilter,...this.getFiltersTemplate}
  }
};
</script>
<style scoped>
.h-35 {
  height: 35px;
}
</style>
